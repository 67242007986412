import BaseController from './base_controller'

export default class extends BaseController {
  connect() {
    this.element.value = this.value()
  }

  value() {
    switch(this.fieldName) {
      case "formatted_offset":
        return this.formattedUTCOffset
    }
  }

  get fieldName() {
    return this.element.name.match(/\[(.*)\]/)[1]
  }

  get formattedHoursOffset() {
    return ("00"+(this.timezoneOffsetHours)).slice(-2)
  }

  get timezoneOffsetHours() {
    return Math.abs(this.timezoneOffset / 60)
  }

  get timezoneOffset() {
    return new Date().getTimezoneOffset()
  }

  get isNegativeUTCOffset() {
    return this.timezoneOffset == Math.abs(this.timezoneOffset)
  }

  get formattedUTCOffset() {
    return `${this.isNegativeUTCOffset ? "-" : ""}${this.formattedHoursOffset}:00`
  }
}
