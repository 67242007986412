import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $(this.element).select2({
      ajax: {
        delay: 250,
        url: this.element.dataset.url,
        dataType: 'json'
      }
    });
  }
}
