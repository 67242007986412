import BaseController from './base_controller'
import $ from 'jquery'

export default class extends BaseController {
  static targets = ["image"]

  connect() {
    if (this.input) this.updateLabel()
  }

  updateLabel(event) {
    if (this.input.checked) {
      this.element.classList.add("active")
    } else {
      this.element.classList.remove("active")
    }
  }

  previewImage(event) {
    event.preventDefault()
    this.modalTag.modal("show")
    this.imgTag.src = event.currentTarget.href
  }

  get input() {
    return this.element.querySelector("input")
  }

  get imgTag() {
    return this.modalTag[0].querySelector("img")
  }

  get modalTag() {
    return $("#modal")
  }
}
