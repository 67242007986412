/*jshint esversion: 6 */

import { Controller } from "stimulus";
import $ from "jquery";

export default class extends Controller {
  static targets = [];

  connect() {
  }

  debounce(func, wait = 100) {
    let timeout;
    return function(...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func.apply(this, args);
      }, wait);
    };
  }

  alert(e) {
    const message = e.currentTarget.dataset.message;
    alert(message);
  }

  utcNow() {
    let now = new Date();
    let utcNow = new Date(
      Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate(),
        now.getUTCHours(),
        now.getUTCMinutes(),
        now.getUTCSeconds(),
        now.getUTCMilliseconds()
      )
    );
    return utcNow;
  }

  insertSpinner(e) {
    e.currentTarget.classList.add("disabled")
    e.currentTarget.innerHTML = `<i class="fas fa-spinner fa-spin"></i>`
  }

  mapStyles() {
    return [
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [{ color: "#d3d3d3" }],
      },
      {
        featureType: "transit",
        stylers: [{ color: "#808080" }, { visibility: "off" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [{ visibility: "on" }, { color: "#b3b3b3" }],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.highway",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#ffffff" }, { weight: 1.8 }],
      },
      {
        featureType: "road.local",
        elementType: "geometry.stroke",
        stylers: [{ color: "#d7d7d7" }],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#ebebeb" }],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [{ color: "#a7a7a7" }],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [{ color: "#ffffff" }],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [{ visibility: "on" }, { color: "#efefef" }],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [{ color: "#696969" }],
      },
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [{ visibility: "on" }, { color: "#737373" }],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          { visibility: "on" },
          { lightness: 10 },
          { saturation: -100 },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.stroke",
        stylers: [{ color: "#d6d6d6" }],
      },
      {
        featureType: "road",
        elementType: "labels",
        stylers: [{ visibility: "off" }],
      },
      {},
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [{ color: "#dadada" }],
      },
    ];
  }
}
