import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ["dropdown", "firstName", "lastName", "email"]

  initialize() {
    this.getUsers = this.debounce(this.getUsers, 250).bind(this)
  }

  getUsers() {
    $.ajax({
      method: "GET",
      url: this.emailTarget.dataset.url,
      data: {
        term: this.emailTarget.value
      }
    })
  }

  searchUsers(event) {
    if (this.emailTarget.value) {
      this.dropdownTarget.classList.add('show')
    } else {
      this.dropdownTarget.classList.remove('show')
    }
    this.getUsers();
  }

  fillOutInputs(event) {
    const dataset = event.currentTarget.dataset
    this.firstNameTarget.value = dataset.firstName
    this.lastNameTarget.value = dataset.lastName
    this.emailTarget.value = dataset.email
    this.dropdownTarget.classList.remove('show')
  }

  showDropdown(event) {
    if (this.emailTarget.value) {
      this.dropdownTarget.classList.add('show')
    }
  }

  hideDropdown(event) {
    this.dropdownTarget.classList.remove('show')
  }
}
