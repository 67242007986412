import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    window.location = this.url
  }

  get url() {
    return this.element.dataset.url
  }
}
