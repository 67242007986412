import BaseController from './base_controller'

export default class extends BaseController {
  connect() {
    this.setFadeout()
  }

  setFadeout() {
    const flashCallback = () => {
      $(this.element).fadeOut();
    };    
    setTimeout(flashCallback, 8000);
  }
}
