/*jshint esversion: 6 */

import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [
    "form",
    "table",
    "dropdown",
  ];

  connect() {
    StimulusReflex.register(this)
  }

  afterReflex() {
    if (!!this.tableTarget) {
      $('.data-table').DataTable({
        pageLength: 25,
        order: [],
        responsive: {
          details: {
              type: 'column',
              target: 'tr'
          }
        }
      })
    }
  }

  setDefault(event) {
    event.preventDefault()
    if (!this.dropdownTarget.value) {
      var message = 'Removing the default policy will remove it from all locations.'
    } else {
      var message = 'This policy will be set on all current and future locations.'
    }
    const alertResponse = confirm(message)
    if (alertResponse) {
      this.formTarget.submit()
    }
  }

  destroy_policy(event) {
    if (event.currentTarget.dataset.default == 'true') {
      alert(`Cannot remove default policy. First select a new policy as a default in the top right corner.`)
      return event.preventDefault()
    } else {
      const alertResponse = confirm(`Delete Policy?`)
      event.preventDefault()
      if (!!alertResponse) {
        this.stimulate('OrganizationPolicyReflex#destroy_policy', event.currentTarget.dataset.id, event.currentTarget.dataset.orgId)
      }
    }
  }

}
