import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "title",
    "description",
    "choice",
    "choices",
    "templates",
    "choiceInput",
    "choiceRemoveIcon"
  ]

  connect() {
  }

  titleInput() {
    this.titleValue = event.target.value
    this.titleTarget.innerHTML = this.titleValue
  }

  descriptionInput() {
    const target = event.target
    setTimeout(() => { this.descriptionUpdateThrottle(target) }, 250)
  }

  choicesValue = []
  choiceInput() {
    const index = this.choiceInputTargets.indexOf(event.target) - 1
    if(index === -1) {
      this.choiceTarget.setAttribute("placeholder", event.target.value)
    } else {
      this.choicesValue[index] = event.target.value
      this.choiceTargets[index].innerHTML = this.choicesValue[index]
    }
  }

  addChoicePreview() {
    const template = this.getTemplate(this.questionType).replace("choiceTemplate", "choice")
    this.choicesTarget.insertAdjacentHTML("beforeEnd", template)
  }

  removeChoicePreview() {
    const index = this.choiceRemoveIconTargets.indexOf(event.currentTarget)
    this.choiceTargets[index].closest(".choice-template").remove()
  }

  resetChoicesPreview() {
    this.choicesTarget.innerHTML = ""
    this.addChoicePreview()
    this.choicesValue = []
  }

  getTemplate(type) {
    return this.templatesTarget.querySelector(`[data-type=${type}]`).outerHTML
  }

  descriptionUpdateThrottle(target) {
    this.descriptionValue = target.value
    this.descriptionTarget.innerHTML = this.descriptionValue
  }

  get questionType() {
    return this.element.querySelector("#question_question_type").value
  }
}
