/*jshint esversion: 6 */

import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ['table'];

  connect() {
    StimulusReflex.register(this)
  }

  afterReflex() {
    if (!!this.tableTarget) {
      var options = {
        pageLength: 25,
        order: [],
        responsive: {
          details: {
              type: 'column',
              target: 'tr'
          }
        },
        'serverSide': true,
        'processing': true,
        'columns': [
          { orderable: false }, // actions
          null, // name
          null, // id
          null, // email
          { orderable: false }, // check_ins.size
          null // role
        ]
      }

      $('.data-table').DataTable(options);
    }
  }

  destroy_user(event) {
    const alertResponse = confirm(`Delete user from organization?`)
    event.preventDefault()
    if (!!alertResponse) {
      this.stimulate('OrganizationUserReflex#destroy_user', event.currentTarget.dataset.id)
    }
  }

  make_trusted_user(event) {
    const alertResponse = confirm(`Make user limited admin? They will not be able to see uploaded documents.`)
    event.preventDefault()
    if (!!alertResponse) {
      this.stimulate('OrganizationUserReflex#make_trusted_user', event.currentTarget.dataset.id)
    }
  }

  alert_cannot_delete_last_admin(event) {
   alert(`Cannot delete the last admin tied to an Organization.`)
   event.preventDefault()
  }

  alert_make_trusted_reviewer(event) {
    alert(`This will make a user a trusted reviewer of an Organization.`)
    event.preventDefault()
  }
}
