import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["map"];

  connect() {
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  initMap() {
    this.map = new google.maps.Map(this.mapTarget, {
      center: new google.maps.LatLng(
        this.data.get("latitude") || 43.616,
        this.data.get("longitude") || -116.205116
      ),
      zoom: this.data.get("latitude") == null ? 4 : 15,
    });

    this.marker = new google.maps.Marker({
      map: this.map,
      anchorPoint: new google.maps.Point(
        this.data.get("latitude"),
        this.data.get("longitude")
      ),
    });
  }
}
