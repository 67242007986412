import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = ["map", "table"];

  connect() {
    StimulusReflex.register(this)
  }

  afterReflex() {
    if (!!this.tableTarget) {
      $('.data-table').DataTable({
         th: 25,
        order: [],
        responsive: {
          details: {
              type: 'column',
              target: 'tr'
          }
        },
        serverSide: true,
        processing: true,
        drawCallback: function (settings) {
                        const hashIds = []
                        for (let i = 0; i < settings.json.data.length; i++) {
                          const dataArry = settings.json.data[i];
                          hashIds.push(dataArry[dataArry.length-1])
                        }
                        const event = document.createEvent('Event');
                        event.initEvent('input', true, true);
                        const inputElement = document.getElementById('ajax-locations')
                        if (hashIds.length > 0){
                          inputElement.value = hashIds.join(' ');
                          inputElement.dispatchEvent(event);
                        }
                      },
      })
    }
  }

  refreshMap(event) {
    if (typeof google != "undefined" && this.hasMapTarget) {
      this.initMap();
      this.load(event.currentTarget.value);
    }
  }

  initMap() {
    this.bounds = new google.maps.LatLngBounds();
    this.map = new google.maps.Map(this.mapTarget, {
      zoom: 8,
    });
  }

  load(locationHashIds) {
    const CSRF_TOKEN = document.querySelector("[name='csrf-token']").content
    fetch(`/api/v1/organizations/${this.data.get("organization")}/locations?hash_ids=${locationHashIds}`, {
      headers: {
        "Authorization": CSRF_TOKEN,
        'Content-Type': 'application/json'
      },
    }).then(response => response.json())
      .then(data => {
        data.map((location) => {
          this.point = new google.maps.LatLng(parseFloat(location.latitude,10), parseFloat(location.longitude,10))
          this.marker = new google.maps.Marker({
            map: this.map,
            position: this.point,
            animation: google.maps.Animation.DROP,
            title: location.name
          });
          this.bounds.extend(this.point)
        });
        this.map.fitBounds(this.bounds);
      }).catch(err => {
        console.error('Error: ', err);
    });
  }

  destroy_location(event) {
    const alertResponse = confirm(`Delete location from organization?`)
    if (!!alertResponse) {
      this.stimulate('LocationReflex#destroy_location', event.currentTarget.dataset.id)
    }
    return event.preventDefault();
  }
}
