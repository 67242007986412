import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ["field"];

  toggleField(event) {
    if(event.currentTarget.checked) {
      this.fieldTarget.classList.add("disabled")
    } else {
      this.fieldTarget.classList.remove("disabled")
    }
  }
}
