import BaseController from './base_controller'

export default class extends BaseController {
  static targets = [
    'map',
    'form',
    'status'
  ];

  connect() {
    if (typeof google != "undefined") {
      this.initMap();
    }
  }

  reviewerGrants(e) {
    this.statusTarget.value = 'checked_in';
    this.setTurboAndSubmitForm();
  }

  reviewerDenies(e) {
    this.statusTarget.value = 'denied';
    this.setTurboAndSubmitForm();
  }

  reviewerCancels(e) {
    this.statusTarget.value = 'canceled';
    this.setTurboAndSubmitForm();
  }

  reviewerFlags(e) {
    this.statusTarget.value = 'flagged';
    this.setTurboAndSubmitForm();
  }

  setTurboAndSubmitForm() {
    this.formTarget.setAttribute('data-turbo', false)
    this.formTarget.submit();
  }

  initMap() {
    if (!this.data.get("latitude") || !this.data.get("longitude")) { return }

    var myLatLng = new google.maps.LatLng(this.data.get("latitude"), this.data.get("longitude"))
    this.map = new google.maps.Map(this.mapTarget, {
      center: myLatLng,
      zoom: 17,
      styles: this.mapStyles()
    });

    this.marker = new google.maps.Marker({
      map: this.map,
      position: myLatLng,
      icon: {
        scaledSize: new google.maps.Size(40, 40),
        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png"
      }
    });
  }

}
