import BaseController from './base_controller'
import $ from "jquery"

export default class extends BaseController {
  static targets = ["hashId"]

  submit() {
    $("#check-in-scan")
      .modal("show")
      .find(".modal-body")
      .html(`
        <div class="p-3 text-center text-muted">
          <i class="fas fa-circle-notch fa-spin fa-9x"></i>
        </div>
      `)
    Rails.fire(this.element, "submit")
  }
}
