import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ["display"]

  connect() {
    this.buttonText = this.displayTarget.innerHTML
    this.maxFileSize = 4194304 // 4mb
    this.form = document.querySelector(`#${this.element.dataset.form}`)
    this.fileInput.addEventListener("change", (event) => this.formSubmit())
  }

  openFileInput(event) {
    event.preventDefault()
    this.fileInput.removeAttribute("camera")
    this.fileInput.click()
  }

  openCameraInput(event) {
    event.preventDefault()
    this.fileInput.setAttribute("camera", "environment")
    this.fileInput.click()
  }

  isFilesSizeValid(files) {
    const largestSubmitedFileSize = [...files].map((file) => file.size).sort().pop()
    return largestSubmitedFileSize < this.maxFileSize
  }

  formSubmit() {
    this.loadSpinner(true)
    if (this.isFilesSizeValid(this.fileInput.files)) {
      this.form.submit()
    } else {
      this.loadSpinner(false)
      alert(this.element.dataset.fileSizeError)
    }
  }

  loadSpinner(isLoading) {
    if(isLoading) {
      this.element.classList.add("disabled")
      this.displayTarget.innerHTML = this.element.dataset.disableWith
    } else {
      this.element.classList.remove("disabled")
      this.displayTarget.innerHTML = this.buttonText
    }
  }

  get fileInput() {
    return this.form.querySelector("[type='file']")
  }
}
