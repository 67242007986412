// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("local-time").start();
require("src/gmaps");
require("typeface-inter");
require("typeface-oxygen");
require("chartkick")
require("chart.js")
require("@hotwired/turbo-rails")

window.$ = $
require('imports-loader?define=>false!datatables.net')(window, $)
require('imports-loader?define=>false!datatables.net-bs4')(window, $)
require('imports-loader?define=>false!datatables.net-responsive')(window, $)
require('imports-loader?define=>false!datatables.net-responsive-bs4')(window, $)
require('imports-loader?define=>false!select2')(window, $)

import { initSelect2 } from "../plugins/select2"
import { initTrixPostProcessing } from "../plugins/trix_post_processing"

const dataTables = [];

document.addEventListener("turbo:load", () => {
  initTrixPostProcessing()
  initSelect2();
  if (dataTables.length === 0 && $('.data-table').length !== 0) {
    $('.data-table').each((_, element) => {
      // Select/configure the table only once to avoid https://datatables.net/manual/tech-notes/3
      var options = {
        pageLength: 25,
        order: [],
        responsive: {
          details: {
              type: 'column',
              target: 'tr'
          }
        },
        bAutoWidth: false
      }

      var dataObject = $(element).data()
      if (dataObject.hasOwnProperty('ajax')) {
        options['serverSide'] = true;
        options['processing'] = true;
        options['bDeferRender'] = true;
        if (dataObject.hasOwnProperty('drawcallback')) {
          options["drawCallback"] = function (settings) {
                                      const hashIds = []
                                      for (let i = 0; i < settings.json.data.length; i++) {
                                        const dataArry = settings.json.data[i];
                                        hashIds.push(dataArry[dataArry.length-1])
                                      }
                                      const event = document.createEvent('Event');
                                      event.initEvent('input', true, true);
                                      const inputElement = document.getElementById(dataObject.drawcallback);
                                      if (hashIds.length > 0) {
                                        inputElement.value = hashIds.join(' ');
                                        inputElement.dispatchEvent(event);
                                      }
                                    }
        }
      }

      var sorting = [];
      $(element).find('tr').first().each(function(i, tr) {
        $(tr).children().each(function(k, td) {
          if ($(td).hasClass('not-sortable')) {
            sorting.push({ orderable: false })
          } else {
            sorting.push(null)
          }
        })
      })

      options['columns'] = sorting

      var table = $(element).DataTable(options);
      var el = document.querySelector('.dataTables_filter input'),
        input = el.cloneNode(true);
      el.parentNode.replaceChild(input, el);
      let searchInterval = null;
      const searchUnlessLimit = (event) => {
        if (input.value.length < 3 && event.keyCode != 13) return;
        clearTimeout(searchInterval);
        searchInterval = setInterval(() => {
          table.search(input.value).draw();
          clearTimeout(searchInterval);
        }, 500)
      }
      input.addEventListener("keyup", searchUnlessLimit)

      dataTables.push(table);

      table.columns( '.hide-column' ).visible( false );

      $('input.custom-search-box').keyup(function(){
        table.search($(this).val()).draw() ;
      })
    });
  }
});

document.addEventListener("turbo:before-cache", () => {
  while (dataTables.length !== 0) {
    dataTables.pop().destroy();
  }

  if ($("select.select2").length) {
    $("select.select2").select2('destroy')
  }
});

window.Rails = Rails;


require("bootstrap");

// import "data-confirm-modal"; // Not sure why this doesn't work.

require.context("../images", true);

require("trix");
require("@rails/actiontext");

import "controllers";

window.initMap = function (...args) {
  const event = document.createEvent("Events");
  event.initEvent("google-maps-callback", true, true);
  event.args = args;
  window.dispatchEvent(event);
};
