import BaseController from './base_controller'
import $ from 'jquery'

export default class extends BaseController {

  connect() {
    $(`#${this.element.id}`).modal("show")
  }

  get modalTag() {
    return $(`#${this.element.id}`)
  }

}
