import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    Rails.ajax({
      url: `${this.url}?referrer=${this.referrer}`,
      type: this.method,
      success: (data) => {
        console.log(data)
      },
      error: (data) => {
        console.log(data)
      }
    })
  }

  get url() {
    return this.element.dataset.url
  }

  get method() {
    return this.element.dataset.method
  }

  get referrer() {
    return this.element.dataset.referrer
  }
}
