import BaseController from './base_controller'
import $ from 'jquery'

export default class extends BaseController {
  static targets = [
    'newLocationName',
    'newLocationId'
  ];

  showModal(e) {
    e.preventDefault()
    const data = e.currentTarget.dataset
    this.newLocationIdTarget.value = data.newLocationId
    for (const locationNameTarget of this.newLocationNameTargets) {
      if (locationNameTarget.tagName == 'SPAN')
        locationNameTarget.innerHTML = `"Check Into ${data.newLocationName}"`
      else {
        locationNameTarget.value = `Check Into ${data.newLocationName}`
      }
    }
    this.modalTag.modal("show")
  }

  get modalTag() {
    return $("#home-modal")
  }

}
