import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["choices"]

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
  }

  mark_deny_entry(e) {
    const isChecked = e.currentTarget.checked
    const denyAccessField = e.currentTarget.closest(".nested-fields").querySelector(".deny-access-icon")
    if (isChecked) {
      denyAccessField.classList.remove("invisible")
    } else {
      denyAccessField.classList.add("invisible")
    }
  }

  swap_type_form(e) {
    const type = e.currentTarget.value
    const questionId = e.currentTarget.dataset.questionId
    let url = `/forms/question_fields?question_type=${type}`
    if (questionId) {
      url += `&question_id=${questionId}`
    }
    Rails.ajax({
      url: url,
      type: "get",
      success: (data) => {
        this.choicesTarget.innerHTML = data
      }
    })
  }
}
