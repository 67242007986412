/*jshint esversion: 6 */

import ApplicationController from './application_controller'
import StimulusReflex from 'stimulus_reflex'

export default class extends ApplicationController {
  static targets = [
    "field",
    "map",
    "latitude",
    "longitude",
    "address",
    "city",
    "state",
    "postal",
    "country",
    "name",
    "inactivate_policy",
    "messagesDisplay",
    "messagesForm",
  ];

  connect() {
    StimulusReflex.register(this)
    if (this.hasMapTarget) {
      this.initMap();
    }
  }

  afterReflex() {
    if (this.hasMapTarget) {
      this.initMap();
    }
  }

  copyOrganizationFields(e) {
    const organization = JSON.parse(e.currentTarget.dataset.organization);
    this.nameTarget.value = organization.name
    this.addressTarget.value = organization.address
    this.cityTarget.value = organization.city
    this.stateTarget.value = organization.state
    this.countryTarget.value = organization.country
    this.postalTarget.value = organization.postal
  }

  initMap() {
    if (typeof google == "undefined") { return; }
    if (!this.hasMapTarget) { return; }

    const myLatLng = new google.maps.LatLng(
      this.data.get("latitude") || 43.616,
      this.data.get("longitude") || -116.205116
    )

    this.map = new google.maps.Map(this.mapTarget, {
      center: myLatLng,
      zoom: this.data.get("latitude") == null ? 4 : 15,
      styles: this.mapStyles(),
    });

    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget);
    this.autocomplete.bindTo("bounds", this.map);
    this.autocomplete.setFields([
      "address_components",
      "geometry",
      "icon",
      "name",
    ]);
    this.autocomplete.addListener(
      "place_changed",
      this.placeChanged.bind(this)
    );

    this.marker = new google.maps.Marker({
      map: this.map,
      position: myLatLng,
      anchorPoint: new google.maps.Point(0, -29),
      icon: {
        scaledSize: new google.maps.Size(40, 40),
        url: "http://maps.google.com/mapfiles/ms/icons/green-dot.png",
      },
    });
  }

  placeChanged() {
    let place = this.autocomplete.getPlace();

    if (!place.geometry) {
      window.alert(`No details available for input: ${place.name}`);
      return;
    }

    if (place.geometry.viewport) {
      this.map.fitBounds(place.geometry.viewport);
    } else {
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(17);
    }

    if (place.address_components) {
      const addressComponents = place.address_components.reverse(); // So country is set first
      for (let i = 0; i < addressComponents.length; i++) {
        const address_component = addressComponents[i];

        if (address_component.types.indexOf('street_number') != -1) {
          this.addressTarget.value = address_component.short_name;
          continue;
        }

        else if (address_component.types.indexOf('route') != -1) {
          this.addressTarget.value += " " + address_component.short_name;
          continue;
        }

        else if (address_component.types.indexOf('locality') != -1) {
          this.cityTarget.value = address_component.long_name;
          continue;
        }

        else if (address_component.types.indexOf('administrative_area_level_2') != -1) {
          if (this.countryTarget.value != 'US') {
            this.stateTarget.value = address_component.short_name;
          }
          continue;
        }

        else if (address_component.types.indexOf('administrative_area_level_1') != -1) {
          if (this.countryTarget.value == 'US') {
            this.stateTarget.value = address_component.short_name;
          }
          continue;
        }

        else if (address_component.types.indexOf('postal_code') != -1) {
          this.postalTarget.value = address_component.short_name
        }

        else if (address_component.types.indexOf('country') != -1) {
          this.countryTarget.value = address_component.short_name
        }
      }
    }

    if (place.name && place.name != this.addressTarget.value) {
      this.nameTarget.value = place.name
    }

    this.marker.setPosition(place.geometry.location);
    this.marker.setVisible(true);

    this.latitudeTarget.value = place.geometry.location.lat();
    this.longitudeTarget.value = place.geometry.location.lng();
  }

  keydown(event) {
    if (event.keyCode == 13) {
      event.preventDefault();
      return false;
    }
  }

  setDurationType(event) {
    let duration = document.getElementById('duration')
    let durationInput = document.getElementById('location_check_in_duration_hours')
    let expiration = document.getElementById('expiration')
    let expirationInput = document.getElementById('location_check_in_expiration_date')

    if (event.currentTarget.value == 'duration') {
      duration.classList.remove('hidden')
      expirationInput.value = null;
      expiration.classList.add('hidden')
    } else {
      expiration.classList.remove('hidden')
      durationInput.value = null;
      duration.classList.add('hidden')
    }
  }

  notificationSet(event) {
    let notifyReviewer = document.getElementById('location_notifies_reviewer_granted_check_in')
    let notifySecondCheckIn = document.getElementById('location_notifies_granted_but_another')
    // let secondCheckInReminder = document.getElementById('location_reminder_of_second_check_in_date')
    // let showHideSecondCheckInReminder = document.getElementById('reminder-date')

    if (event.currentTarget == notifyReviewer) {
      notifySecondCheckIn.checked = false;
      // showHideSecondCheckInReminder.classList.add('hidden');
      // secondCheckInReminder.value = null;
    } else {
      notifyReviewer.checked = false;
      // showHideSecondCheckInReminder.classList.remove('hidden');
    }
  }

  destroy_policy(event) {
    const alertResponse = confirm(`Delete policy from this location?`)
    if (!!alertResponse) {
      this.stimulate('LocationReflex#destroy_policy', event.currentTarget.dataset.id)
    }
    return event.preventDefault();
  }

  displayStatusMessagesFields(event) {
    event.preventDefault()
    console.log(this.nameTarget)

    event.currentTarget.classList.add('hidden')
    this.messagesDisplayTarget.classList.add('hidden')
    this.messagesFormTarget.classList.remove('hidden')
  }
}
