import BaseController from './base_controller'
import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.css"

export default class extends BaseController {
  connect() {
    flatpickr(this.element, {
      enableTime: true,
      dateFormat: "Z",
      altInput: true,
      altFormat: "M J at h:iK",
    });

  }
}
