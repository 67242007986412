import BaseController from './base_controller'

export default class extends BaseController {

  disconnect() {
    if (window.purechatApi) {
      window.purechatApi = null;
    }
  }

  open(event) {
    let firstName = event.currentTarget.dataset.firstName;
    let email = event.currentTarget.dataset.email;

    window.purechatApi = { l: [], t: [], on: function () { this.l.push(arguments); } }; (function () { var done = false; var script = document.createElement('script'); script.async = true; script.type = 'text/javascript'; script.src = 'https://app.purechat.com/VisitorWidget/WidgetScript'; document.getElementsByTagName('HEAD').item(0).appendChild(script); script.onreadystatechange = script.onload = function (e) { if (!done && (!this.readyState || this.readyState == 'loaded' || this.readyState == 'complete')) { var w = new PCWidget({c: '53989aec-34e6-4f97-aa07-a02ddc6d9195', f: true }); done = true; } }; })();

    purechatApi.on('chatbox:ready', function () {
      purechatApi.set('chatbox.position', 'bottomRight'); // 'bottomLeft', 'bottomRight', 'topLeft', and 'topRight'
      purechatApi.set('chatbox.expanded', true);

      if (firstName) { purechatApi.set('visitor.firstName', firstName); }
      if (email) { purechatApi.set('visitor.email', email); }
    });

    purechatApi.on('chatbox:collapse', function (args) {
      purechatApi.set('chatbox.visible', false);
    });
  }
}
