import BaseController from "./base_controller"
import $ from 'jquery'
import jsQR from "jsqr"

export default class extends BaseController {
  connect() {
    this.video = document.createElement("video")
    this.canvasElement = document.createElement("canvas")
    this.canvasContext = this.canvasElement.getContext("2d")

    if(this.insertedModal == null) this.buildModal()
  }

  startCameraStream(stream) {
    this.bootstrapModal.modal("show")
    this.bootstrapModal.on("hide.bs.modal", _ => this.stopCameraStream(stream))

    this.video.srcObject = stream
    this.video.setAttribute("playsinline", true)
    this.video.play()
    requestAnimationFrame(_ => this.tick())
  }

  stopCameraStream(stream) {
    stream.getTracks().forEach(track => track.stop())
  }

  tick() {
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.canvasElement.hidden = false

      this.canvasElement.height = this.video.videoHeight
      this.canvasElement.width = this.video.videoWidth
      this.canvasContext.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height)
      const imageData = this.canvasContext.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height)
      const code = jsQR(imageData.data, imageData.width, imageData.height, { inversionAttempts: "dontInvert" })
      if (code) this.captureCodeData(code.data)
    }
    requestAnimationFrame(_ => this.tick())
  }

  captureCodeData(value) {
   this.bootstrapModal.modal("hide")
   this.input.value = value
   this.input.dispatchEvent(new Event("change"))
  }

  requestCamera(facingOption) {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: facingOption } })
      .then(stream => this.startCameraStream(stream))
      .catch(_ => alert("No camera found"))
  }

  startScanner(event) {
    event.preventDefault()
    this.requestCamera("environment")
  }

  buildModal() {
    document.body.insertAdjacentElement("beforeEnd", this.modalElement)
    this.modalElement.querySelector(".modal-dialog").insertAdjacentElement("beforeEnd", this.canvasElement)
  }

  get input() {
    return this.element.closest(".form-group").querySelector("input")
  }

  get bootstrapModal() {
    return $(document.querySelector("#qr-stream"))
  }

  get modalElement() {
    if(this.modal == null) {
      this.modal = document.createElement("div")
      this.modal.classList.add("modal", "fade")
      this.modal.id = "qr-stream"
      this.modal.ariaHidden = true
      this.modal.setAttribute("aria-labelledby", "qr-stream-label")
      this.modal.innerHTML = '<div class="modal-dialog"></div>'
    }
    return this.modal
  }
}
