import BaseController from './base_controller'

export default class extends BaseController {
  static targets = ["timer"]
  static checkOutTime

  connect() {
    const timeTarget = this.timerTarget
    const converTimeToNumber = Number(timeTarget.dataset.checkoutTime)
    this.checkOutTime = new Date(converTimeToNumber).getTime()

    this.startTimer = setInterval(() => {
      timeTarget.innerHTML = `
        <h1 class='mb-0'>${this.timeDisplay}</h1>
        <p class='mb-0'>until your check-in expires</p>
      `
    }, 1000)

    this.startTimer
  }

  disconnect() {
    clearInterval(this.startTimer)
  }

  pluralizeDayWord(count) {
    return `${count} ${count > 1 ? "days" : "day"}`
  }

  get nowTime() {
    return new Date().getTime()
  }

  get isExpired() {
    return this.distanceInSeconds < 0
  }

  get distanceInDays() {
    return this.distanceInHours / 24
  }

  get days() {
    return Math.floor(this.distanceInDays)
  }

  get distanceInHours() {
    return this.distanceInMinutes / 60
  }

  get hours() {
    return Math.floor(this.distanceInHours) % 24
  }

  get distanceInMinutes() {
    return this.distanceInSeconds / 60
  }

  get minutes() {
    return Math.floor(this.distanceInMinutes) % 60
  }

  get distanceInSeconds() {
    return (this.checkOutTime - this.nowTime) / 1000
  }

  get seconds() {
    return Math.floor(this.distanceInSeconds) % 60
  }

  get additionalHours() {
    if (this.distanceInDays >= 1) {
      let hours = Math.floor((this.distanceInDays) * 24);
      return hours >= 0 ? hours : 0
    } else {
      return 0;
    }
  }

  get timeDisplay() {
    if (this.isExpired) {
      return "0:00:00"
    } else if (this.days > 0 && this.hours === 23) {
      return `${this.pluralizeDayWord(this.days + 1)}`
    } else if (this.days > 4) {
      return `${this.pluralizeDayWord(this.days)}+`
    } else {
      return `${this.hours + this.additionalHours}:${this.pad(this.minutes)}:${this.pad(this.seconds)}`
    }
  }

  pad(number, pad_count) {
    return ("00" + number).slice((pad_count || 2) * -1)
  }
}
